.section {
  .container {
    position: relative;
    background: inherit;
    padding: rem-calc(80 0 160 0);

    @include breakpoint(medium) {
      padding: rem-calc(160 0);
    }

    &:before {
      content: '';
      display: none;
      position: absolute;
      left: 0;
      width: 100%;
      z-index: 6;
      background: inherit;
      mask: url('../img/dist/section.svg') no-repeat;
      mask-position: left bottom;
      top: -155px;
      height: 160px;
      
      @supports(mask-image: url("")) {
        display: block;
      }

      @include breakpoint(medium) {
        top: -235px;
        height: 240px;
      }
    }
  }

  .row {
    position: relative;
    z-index: 10;
  }

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__heading {
    position: sticky;
    top: 50%;
    left: 0;
    opacity: 0.2;
    font-family: $global-font-family;
    color: $white;
    font-size: rem-calc(80);
    margin: 0;
    display: block;
    text-transform: uppercase;

    @include breakpoint(medium) {
      font-size: rem-calc(200);
    }
  }

  &--pink {
    background: $pink-200 url('../img/dist/patterns/polka-dots.svg') left / 32px auto;
    background-attachment: fixed;
  }

  &--blue {
    background: $blue-200 url('../img/dist/patterns/zig-zag.svg') left / 48px auto;
    background-attachment: fixed;
  }

  &--yellow {
    background: $yellow-200 url('../img/dist/patterns/tic-tac-toe.svg') left / 48px auto;
    background-attachment: fixed;
  }

  &--large-top {
    @include breakpoint(medium) {
      padding-top: rem-calc(240);
    }
  }
}