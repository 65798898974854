blockquote {
  border-left: 0;
  padding-left: 0;

  p {
    margin-bottom: 0;
    color: $medium-gray;
    font-style: italic;
  }

  cite {
    font-style: normal;
  }
}

h1, h2, h3, h4, h5 {
  text-transform: uppercase;
  font-family: $global-font-family;
  letter-spacing: .1em;
}

h3, .subtitle {
  text-transform: uppercase;
  font-family: $global-font-family;
  font-weight: bold;
  letter-spacing: .1em;
  font-size: rem-calc(13);
  margin-bottom: rem-calc(8);
}

table {
  font-size: rem-calc(14);
  display:block;
  overflow:auto;
  width:100%;

  tbody {
    background: rgba($white, 0.3);
  }
}