html {
	scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

.text-center {
  text-align: center;
}

.text-md-right {
  @include breakpoint(medium) {
    text-align: right;
  }
}

.ohnohoney {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}