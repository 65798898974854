.footer {
  padding: rem-calc(32 0);
  text-align: center;

  &__logo {
    margin: rem-calc(16 80);

    @include breakpoint(medium) {
      margin: rem-calc(0 80);
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
    }
  }

  a {
    position: relative;
    padding: rem-calc(16);
    text-decoration: none;
    color: inherit;
    font-size: $paragraph-font-size;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) scaleX(0);
      width: 100%;
      height: 3px;
      background: $yellow-400;
      transition: cubic-bezier(0.280, 0.840, 0.420, 1) .4s all;
    }

    &:hover {
      &:after {
        transform: translateX(-50%) scaleX(1);
      }
    }
  }
}
