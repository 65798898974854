.hero {
  position: relative;
  background: $light-gray url('../img/dist/patterns/falling-triangles.svg') left / 48px auto;
  padding: rem-calc(240 0 128 0);

  @include breakpoint(medium) {
    padding: rem-calc(240 0 220 0);
  }

  .column {
    position: relative;
  }

  h1 {
    color: $pink-400;
  }

  &__image {
    transform-origin: 50% 50%;
    border-radius: 8px;
    box-shadow: 0 22px 34px rgba($black, 0.05);
    transform: rotate(8deg) scale(0.8);
    margin-bottom: rem-calc(-160);

    @include breakpoint(medium) {
      position: absolute;
      transform: scale(1.2) rotate(20deg) translateY(-20%);
      z-index: 10;
      margin: 0;
    }
  }
}