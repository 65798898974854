// Global
$global-font-family: antarctican-mono, monospace;

$global-font-size: 16px;
$global-line-height: 1.2;
$global-font-weight: 300;
$text-color: #383232;
$global-width: 1200px;

$global-margin: 2rem;
$global-padding: 2rem;
$global-left: 1rem;

$global-weight-normal: 300;
$global-weight-bold: 700;

// Colour Scheme
$primary: $text-color;
$secondary: #767676;
$success: #3adb76;
$warning: #ffae00;
$alert: #cc4b37;

$pink-200: #FFB3B5;
$pink-400: #FF9193;

$turquoise-400: #6CC9C7;

$blue-200: #B2E7ED;
$blue-400: #3061AA;

$yellow-200: #FDE054;
$yellow-400: #EDB605;

$light-gray: #fafafa;
$medium-gray: #8a8a8a;
$dark-gray: #666;
$black: #0a0a0a;
$white: #fefefe;

// Typography - small is effectively mobile

$h1-font-sizes: (
  default: 24px,
  small : 24px,
  medium: 28px,
  large: 32px,
  xlarge: 32px
);

$h2-font-sizes: (
  default: 20px,
  small : 20px,
  medium: 24px,
  large: 28px,
  xlarge: 28px
);

$h3-font-sizes: (
  default: 18px,
  small: 18px,
  medium: 20px,
  large: 20px,
  xlarge: 20px
);

$h4-font-sizes: (
  default: 16px,
  small: 16px,
  medium: 16px,
  large: 18px,
  xlarge: 18px
);

$h5-font-sizes: (
  default: 14px,
  small: 14px,
  medium: 14px,
  large: 16px,
  xlarge: 16px
);

$h6-font-sizes: (
  small : 13px,
  medium: 13px,
  large: 13px,
  xlarge : 13px
);

$header-font-family: $global-font-family;
$header-font-weight: 400;
$header-text-color: $text-color;
$header-line-height: 1.2;
$header-bottom-margin: rem-calc(16);

$paragraph-font-size: rem-calc(16);
$paragraph-line-height: 2;
$paragraph-margin-bottom: rem-calc(32);

$hr-border: 1px solid $light-gray;

$list-lineheight: $paragraph-line-height;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 1.25rem;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: rem-calc(4);
$blockquote-color: $dark-gray;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-gray;
$cite-font-size: rem-calc(13);
$cite-color: $dark-gray;
$cite-pseudo-content: '\2014 \0020';
$abbr-underline: 1px dotted $black;

// Inputs
$input-height: rem-calc(60);
$input-padding: rem-calc(8);
$input-border-color: $light-gray;
$input-focus-color: $primary;
$input-focus-border-color: $primary;

// Buttons
$button-padding-topbottom: rem-calc(16);
$button-padding-leftright: rem-calc(32);
$button-background: $text-color;
$button-font-family: $global-font-family;
$button-font-weight: bold;
$button-text-color: $white;
$button-text-transform: uppercase;
$button-letter-spacing: .2em;
$button-font-size: rem-calc(14);
$button-border-radius: 4px;

// Tables
$table-striped: even;
$table-striped-background: rgba($white, 0.3);
$table-header-background: rgba($white, 0.3);
$table-header-padding: rem-calc(10 12);
$table-header-weight: $global-weight-bold;
$table-body-background: $white;
$table-body-padding: rem-calc(4 8);
$table-body-weight: $global-weight-normal;
$table-footer-background: $light-gray;
$table-footer-padding: rem-calc(10 12);
$table-footer-weight: $global-weight-normal;

// Embed
$embed-margin-bottom: rem-calc(32);

// Grid
$smbreak: rem-calc(576);
$mdbreak: rem-calc(768);
$lgbreak: rem-calc(992);
$xlbreak: rem-calc(1200);

// Breakpoints
$breakpoints: (
	small-min: $smbreak,
	medium-min: $mdbreak,
	large-min: $lgbreak,
	xlarge-min: $xlbreak,
	small-max: $smbreak - 1,
	medium-max: $mdbreak - 1,
	large-max: $lgbreak - 1,
	xlarge-max: $xlbreak - 1,
);