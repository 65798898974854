 .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: rem-calc(16 0);
  z-index: 10;

  .column {
    @include breakpoint(medium) {
      display: flex;
      align-items: center;
    }
  }

  .logo {
    display: block;
    width: rem-calc(90);
    margin: 0 auto rem-calc(16);

    @include breakpoint(medium) {
      width: rem-calc(140);
      margin: rem-calc(0 32 0 0);
    }
  }

  .menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
    line-height: 1.25;

    @include breakpoint(medium) {
      display: block;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    li {
      display: inline-block;

      a {
        position: relative;
        display: block;
        padding: rem-calc(8);
        text-decoration: none;
        color: inherit;
        font-size: rem-calc(16);
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .2em;

        @include breakpoint(medium) {
          padding: rem-calc(8 16);
          font-size: rem-calc(13);
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%) scaleX(0);
          width: 100%;
          height: 3px;
          background: $yellow-400;
          transition: cubic-bezier(0.280, 0.840, 0.420, 1) .4s all;
        }

        &:hover {
          &:after {
            transform: translateX(-50%) scaleX(1);
          }
        }
      }
    }
  }
}
