.event {
  margin-bottom: rem-calc(32);
  padding: rem-calc(32);
  width: 100%;
  border: 2px dotted $yellow-400;
  
  &__date {
    font-family: $global-font-family;
    margin-bottom: rem-calc(16);
    padding-bottom: rem-calc(16);
    border-bottom: 1px solid rgba($black, 0.1);
    font-size: rem-calc(14);
  }

  p, ul, ol {
    font-size: rem-calc(14);
  }

  ul, ol {
    padding-left: 0;
    margin-left: 0;

    li {
      list-style-type: none;
    }
  }

  button {
    @extend .button;
  }
}