.article {
  margin-bottom: rem-calc(32);
  padding-bottom: rem-calc(16);
  border-bottom: 1px dashed rgba($black, 0.1);

  .subtitle {
    margin-bottom: 0;
  }

  &__image {
    margin-bottom: rem-calc(32);
  }
  
  &__date {
    font-family: $global-font-family;
    font-size: rem-calc(14);
    margin-bottom: rem-calc(16);
  }

  p {
    font-size: rem-calc(14);
  }
}